import { useOrganizationFindAllSuspense } from '../../../libs/ohat-api';
import { Button, Card, Col, Row } from 'antd';
import CsvDownloader from 'react-csv-downloader';
import { format, getNowDownloadLabel, parseDate } from '../../../utils/date';
import { useMemo } from 'react';
import { OrganizationsTable } from '../../components/dashboard/OrganizationsTable';
import { OrganizationType } from '../../../constants/orgType';

export const reportCsvColumns = [
  {
    displayName: '施設名',
    id: 'orgName',
  },
  {
    displayName: '機関種別',
    id: 'orgType',
  },
  {
    displayName: '都道府県',
    id: 'orgPrefecture',
  },
  {
    displayName: '電話番号',
    id: 'orgPhoneNo',
  },
  {
    displayName: '担当者名',
    id: 'accountName',
  },
  {
    displayName: 'メールアドレス',
    id: 'accountEmail',
  },
  {
    displayName: '患者数',
    id: 'patientsCount',
  },
  {
    displayName: '登録日',
    id: 'registeredAt',
  },
  {
    displayName: '最終アクセス日',
    id: 'lastActivityAt',
  },
];

export function OrganizationListPage() {
  const { data } = useOrganizationFindAllSuspense();

  const organizationData = useMemo(() => {
    if (!data) return [];
    const allOrgData = data
      .filter(
        (org) => (org.accounts?.length ?? 0) > 0 && !org.accounts![0].isAdmin,
      )
      .map((org) => {
        const account = org.accounts![0];
        const patients = org.patients ?? [];
        return {
          key: org.id,
          orgName: org.name ?? '',
          orgType: OrganizationType.getByKey(org.type).label,
          orgPrefecture: org.prefecture,
          orgPhoneNo: org.phoneNo ?? '',
          accountName: account?.name ?? '',
          accountEmail: account?.email ?? '',
          patientsCount: `${patients.length}`,
          registeredAt: `${format(account?.registeredAt, 'YYYY/MM/DD HH:mm')}`,
          lastActivityAt: `${format(
            account?.lastActivityAt ?? new Date(),
            'YYYY/MM/DD HH:mm',
          )}`,
        };
      });
    // 登録日順にソートする
    const sortedOrgDate = allOrgData.sort(
      (a, b) =>
        parseDate(b.registeredAt).getTime() -
        parseDate(a.registeredAt).getTime(),
    );
    return sortedOrgDate;
  }, [data]);

  return (
    <Row gutter={[16, 8]} style={{ flex: 1, maxWidth: 1400 }}>
      {/* <Col md={24} xs={24}>
        <AdminHomePageBreadCrumb />
      </Col> */}
      <Col md={24} xs={24}>
        <Card
          title={`施設一覧 (全${organizationData.length}件)`}
          extra={
            <CsvDownloader
              filename={`ohat_report_${getNowDownloadLabel()}`}
              extension=".csv"
              separator=","
              // wrapColumnChar="'"
              columns={reportCsvColumns}
              datas={organizationData}
            >
              <Button>ダウンロード</Button>
            </CsvDownloader>
          }
        >
          <OrganizationsTable organizationData={organizationData} />
        </Card>
      </Col>
    </Row>
  );
}
